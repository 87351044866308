.globalLoader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1024;
  top: 0;
  left: 0;
}
.loaderBack {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loaderBack img {
  animation: orangeIconAnimation 2.3s infinite ease-in-out;
}
@keyframes orangeIconAnimation {
  0%,
  100% {
    filter: blur(0);
    transform: scale(1);
  }
  50% {
    filter: blur(5px);
    transform: scale(0.7);
  }
}
/* .loader {
  width: calc(100px - 24px);
  height: 50px;
  position: relative;
  animation: flippx 2s infinite linear;
}
.loader:before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f47820;
  transform-origin: -24px 50%;
  animation: spin 1s infinite linear;
  box-shadow: rgba(43, 106, 170, 0.56) 0px 22px 70px 4px;
}
.loader:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #94a93b;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: rgba(37, 59, 116, 0.56) 0px 22px 70px 4px;
}

@keyframes flippx {
  0%,
  49% {
    transform: scaleX(1);
  }
  50%,
  100% {
    transform: scaleX(-1);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
} */
